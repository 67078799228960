import { Breakpoints } from '@/constants/constants';
import React, { memo } from 'react';
import {
  Header,
  MenuInner,
  Navigation,
  NavWrapper,
  SpList,
  SpListItem,
  SpListItemLink,
  SpSection,
  styles,
} from '../../Components/StyledComponent';
import MediaQuery from 'react-responsive';
import { slide as Menu } from 'react-burger-menu';
import TermsSection from '../../Components/TermsSection';
import HeaderLogo from '../../Components/HeaderLogo';
import HamburgerMenu from '@/components/atoms/HamburgerMenu';
import { Routings } from '@/common/routings';
import SlashIcon from '@/components/atoms/Icons/SlashIcon';

type Props = {
  isOpenSpMenu: boolean;
  OnClickSpMenu: () => void;
};

const SellerLogoPattern = (props: Props) => {
  const { isOpenSpMenu, OnClickSpMenu } = props;

  return (
    <>
      <Header>
        <HeaderLogo serviceType="seller" />
        <NavWrapper>
          {/* PCヘッダー */}
          {/* リンク表示無し */}

          {/* SPヘッダー */}
          <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
            <Navigation>
              <HamburgerMenu isOpen={isOpenSpMenu} onClick={OnClickSpMenu} />
            </Navigation>
          </MediaQuery>
        </NavWrapper>
      </Header>

      {/* SPメニュー */}
      <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
        <Menu right isOpen={isOpenSpMenu} customBurgerIcon={false} styles={styles}>
          <MenuInner>
            <SpSection>
              <SpList>
                <SpListItem isTop>
                  <SpListItemLink to={Routings.sellerTop.location}>
                    <SlashIcon size="small" color="blue" />
                    トップ
                  </SpListItemLink>
                </SpListItem>
              </SpList>
            </SpSection>
            <TermsSection />
          </MenuInner>
        </Menu>
      </MediaQuery>
    </>
  );
};

export default memo(SellerLogoPattern);
