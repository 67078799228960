import React from 'react';
import SellerHeader from '@/components/atoms/Header/SellerHeader';
import SellerTop from '@/components/organisms/Seller';
import SellerFooter from '@/components/atoms/Footer/SellerFooter';
import { Wrapper } from '@/components/layout/common';
import withInitializeApp from '@/utils/hoc/withInitializeApp';

const SellerTopPage: React.VFC = () => {
  return (
    <Wrapper>
      <SellerHeader displayPattern="all" />
      <SellerTop />
      <SellerFooter showBanner />
    </Wrapper>
  );
};

export default withInitializeApp(SellerTopPage);
