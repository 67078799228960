import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Routings } from '@/common/routings';
import { Breakpoints } from '@/constants/constants';
import BackgroundMap from './BackgroundMap';
import { Main } from '@/components/layout/common';
import theme from '@/styles/theme';
import TriangleArrowIcon from '@/components/atoms/Icons/TriangleArrowIcon';

const StyledMain = styled(Main)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  overflow: hidden;
  padding: 60px 15px 99px 15px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 60px 15px;
  }
`;

const Banner = styled.div`
  width: 100%;
  max-width: 930px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    padding: 40px 20px;
  }
`;

const Lead = styled.h1`
  text-align: center;
  font: ${theme.fonts.bold_30_45};
  color: #4c586f;
  line-heght: 1.5;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    font: ${theme.fonts.bold_16_24};
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 105px;
  margin: 26px 0 15px 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 30px 0;
    height: 75.71px;
  }
`;

const PrArea = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    row-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 21px;
    }
  }
`;

const Pr = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
  font: ${theme.fonts.normal_16_32};
  letter-spacing: 0px;
  color: #333333;
  & > *:first-child {
    margin-right: 4px;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    align-items: flex-start;
    font: ${theme.fonts.normal_14_24};
  }
`;

const SubLead = styled.p`
  font: ${theme.fonts.bold_16_24};
  color: #4c586f;
  margin-top: 37px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-top: 31px;
  }
`;

const PrimaryButton = styled(Link)`
  width: 354px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  background: #4c586f;
  font: ${theme.fonts.bold_16_45};
  color: #ffffff;
  text-decoration: none;
  margin: 5px 0 9px 0;
  opacity: 1;
  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
    margin: 5px 0 5px 0;
  }
`;

const SecondaryButton = styled(Link)`
  width: 258px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font: ${theme.fonts.bold_14_45};
  color: #4c586f;
  text-decoration: none;

  opacity: 1;
  &:hover {
    opacity: 0.9;
  }

  @media screen and (max-width: ${Breakpoints.sp}px) {
    width: 100%;
  }
`;

const AssessmentArea = styled.p`
  font: ${theme.fonts.normal_12_24};
  color: #333333;
  margin-bottom: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin-bottom: 15px;
  }
`;

const SecondaryButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 30px;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    flex-direction: column;
    column-gap: 0;
    & > *:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const SellerTop = () => {
  return (
    <StyledMain>
      <BackgroundMap />
      <Banner>
        <Lead>「買いたい」と「売りたい」をつなぐ</Lead>
        <Logo src="/assets/images/kusabi_logo_bk.svg" alt="" />
        <PrArea>
          <Pr>
            <TriangleArrowIcon />
            AI査定により、ご所有物件の適正な売却価格をすぐにご案内
          </Pr>
          <Pr>
            <TriangleArrowIcon />
            買取再販業者の中間マージンがない、買主との直接取り引き
          </Pr>
        </PrArea>
        <SubLead>約3分でかんたん査定！</SubLead>
        <PrimaryButton to={Routings.search.location}>無料AI査定スタート</PrimaryButton>
        <AssessmentArea>※　対象エリア：東京 埼玉 千葉 神奈川</AssessmentArea>
        <SecondaryButtonArea>
          <SecondaryButton to={Routings.sellerInquiry.location}>エージェントに相談する</SecondaryButton>
          <SecondaryButton to={Routings.buyerTop.location}>直近の成約事例をみる</SecondaryButton>
        </SecondaryButtonArea>
      </Banner>
    </StyledMain>
  );
};

export default SellerTop;
