import React, { memo } from 'react';
import styled from 'styled-components';
import { Breakpoints } from '@/constants/constants';
import ClickHereInquiry from '../../ClickHereInquiry';
import TermsLink from '../Components/TermsLink';
import Copyright from '../Components/Copyright';
import MediaQuery from 'react-responsive';
import FooterLogo from '../Components/FooterLogo';
import ScrollTop from '../../ScrollTop';
import { StyledFooter, Warpper } from '../Components/StyledComponent';

const BannerInner = styled.div`
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 999999;
`;

type Props = {
  showBanner?: boolean;
};

const SellerFooter = (props: Props) => {
  const { showBanner } = props;

  return (
    <Warpper>
      {/* PC用バナー */}
      {showBanner && (
        <MediaQuery minWidth={`${Breakpoints.sp + 1}px`}>
          <BannerInner>
            <ClickHereInquiry serviceType="seller" vertical />
          </BannerInner>
        </MediaQuery>
      )}

      {/* SP用バナー */}
      <MediaQuery maxWidth={`${Breakpoints.sp}px`}>
        {showBanner && <ClickHereInquiry serviceType="seller" />}
        <ScrollTop fullWidth />
      </MediaQuery>

      {/* 共通 */}
      <StyledFooter id="footer">
        <TermsLink />
        <Copyright />
        <FooterLogo />
      </StyledFooter>
    </Warpper>
  );
};

export default memo(SellerFooter);
