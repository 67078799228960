import React, { useEffect, useState } from 'react';
import SellerAllPattern from './SellerAllPattern';
import SellerInquiryPattern from './SellerInquiryPattern';
import SellerLogoPattern from './SellerLogoPattern';

type Props = {
  displayPattern: 'logo' | 'inquiry' | 'all';
};

const SellerHeader = (props: Props) => {
  const { displayPattern } = props;

  const [isOpenSpMenu, setOpenSpMenu] = useState(false);

  useEffect(() => {
    return () => {
      const bodyEl = document.querySelector('body');
      if (!bodyEl) return;
      bodyEl.style.overflow = '';
    };
  }, []);

  const handleOnClickSpMenu = () => {
    const isnextOpen = !isOpenSpMenu;
    setOpenSpMenu(isnextOpen);
    const bodyEl = document.querySelector('body');
    if (!bodyEl) return;
    if (isnextOpen) {
      // SPメニュー表示時は後ろのスクロールを禁止する
      bodyEl.style.overflow = 'hidden';
    } else {
      bodyEl.style.overflow = '';
    }
  };

  return (
    <>
      {/* ロゴのみ表示 */}
      {displayPattern === 'logo' && (
        <SellerLogoPattern isOpenSpMenu={isOpenSpMenu} OnClickSpMenu={handleOnClickSpMenu} />
      )}

      {/* お問合せリンクのみ表示 */}
      {displayPattern === 'inquiry' && (
        <SellerInquiryPattern isOpenSpMenu={isOpenSpMenu} OnClickSpMenu={handleOnClickSpMenu} />
      )}

      {/* 全てのリンク表示 */}
      {displayPattern === 'all' && <SellerAllPattern isOpenSpMenu={isOpenSpMenu} OnClickSpMenu={handleOnClickSpMenu} />}
    </>
  );
};

export default SellerHeader;
