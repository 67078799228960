import { Breakpoints } from '@/constants/constants';
import React from 'react';
import styled from 'styled-components';
import { mapFilePath } from '../Buyer/AreaMap';

const MapWrapper = styled.div`
  /* ブラウザの横幅を変更しても、常に地図をセンターに表示しておくための仕掛け(PC版) */
  width: 100%;
  min-width: 2000px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: scale(1) translate(-52%, -53%);

  @media screen and (max-width: ${Breakpoints.sp}px) {
    /* ブラウザの横幅を変更しても、常に地図をセンターに表示しておくための仕掛け(SP版) */
    transform: scale(0.6) translate(-85%, -90%);
  }
`;

const BackgroundMap = () => {
  return (
    <MapWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2443.46 1814.17">
        <clipPath id="clippath-sea">
          <polygon
            id="sea_path"
            className="polygon-style"
            points="2215.81 1814.17 1752.39 1011.34 1631.28 1011.36 1691.83 1116.25 1570.73 1116.19 1510.23 1011.36 1449.69 1116.21 1328.58 1116.21 1389.13 1221.07 1328.58 1325.96 1207.57 1325.96 1489.68 1814.17 2215.81 1814.17"
          />
        </clipPath>
        <clipPath id="clippath-toshin6ku">
          <polygon
            id="toshin6ku_path"
            className="polygon-style"
            points="1328.58 906.43 1207.47 906.43 1146.92 1011.31 1207.47 1116.19 1328.58 1116.19 1389.13 1011.31 1328.58 906.43"
          />
        </clipPath>
        <clipPath id="clippath-jotoh">
          <polygon
            id="jotoh_path"
            className="polygon-style"
            points="1328.58 696.67 1449.69 696.65 1570.79 906.46 1449.69 1116.21 1328.58 1116.21 1389.13 1011.33 1328.58 906.46 1449.69 906.45 1328.58 696.67"
          />
        </clipPath>
        <clipPath id="clippath-josai">
          <polygon
            id="josai_path"
            className="polygon-style"
            points="1146.92 801.55 1025.81 801.55 965.26 906.43 1086.37 906.43 1025.81 1011.31 1146.92 1011.31 1207.47 906.43 1146.92 801.55"
          />
        </clipPath>
        <clipPath id="clippath-jonan">
          <polygon
            id="jonan_path"
            className="polygon-style"
            points="1328.58 1116.19 1207.47 1116.19 1146.92 1011.31 1025.81 1011.31 1146.92 1221.07 1207.47 1325.96 1328.58 1325.96 1389.13 1221.07 1328.58 1116.19"
          />
        </clipPath>
        <clipPath id="clippath-johoku">
          <polygon
            id="johoku_path"
            className="polygon-style"
            points="1389.13 801.55 1268.03 801.55 1207.47 696.67 1207.47 696.67 1086.36 696.67 1146.92 801.55 1207.47 906.43 1328.58 906.43 1449.69 906.43 1389.13 801.55"
          />
        </clipPath>
        <clipPath id="clippath-kugai23">
          <polygon
            id="_23kugai_path"
            data-name=" 23kugai_path"
            className="polygon-style"
            points="904.7 801.55 965.26 696.67 1025.81 801.55 965.26 906.43 1086.37 906.43 1025.81 1011.31 1086.37 1116.21 844.15 1116.21 783.6 1221.09 844.15 1325.97 0 1325.97 0 801.55 904.7 801.55"
          />
        </clipPath>
        <clipPath id="clippath-kanagawa">
          <polygon
            id="kanagawa_path"
            className="polygon-style"
            points="1086.37 1116.21 844.15 1116.21 783.6 1221.09 844.15 1325.97 0 1325.97 0 1814.17 1489.68 1814.17 1086.37 1116.21"
          />
        </clipPath>
        <clipPath id="clippath-saitama">
          <polygon
            id="saitama_path"
            className="polygon-style"
            points="0 0 0 801.55 904.7 801.55 965.26 696.67 1025.81 801.55 1146.92 801.55 1086.37 696.67 1207.47 696.67 1268.03 801.55 1389.13 801.55 1328.58 696.67 1449.69 696.65 1851.9 0 0 0"
          />
        </clipPath>
        <clipPath id="clippath-chiba">
          <polygon
            id="chiba_path"
            className="polygon-style"
            points="1851.9 0 1449.69 696.65 1570.79 906.46 1510.23 1011.36 1570.73 1116.19 1691.83 1116.25 1631.28 1011.36 1752.39 1011.34 2215.81 1814.17 2443.46 1814.17 2443.46 0 1851.9 0"
          />
        </clipPath>
        {/* 海上 エリア */}
        <g id="sea">
          <g id="sea_clip">
            <g className="sea-i">
              <image
                id="sea_img"
                transform="translate(1204.69 1010.11)"
                href="/assets/images/map/default/map_default_sea.png"
              />
            </g>
          </g>
        </g>
        {/* 都心６区 エリア */}
        <g id="toshin6ku">
          {/* 画像 */}
          <g id="toshin6ku_clip">
            <g className="toshin6ku-i">
              <image id="toshin6ku_img" transform="translate(1139.05 900.91)" href={mapFilePath['toshin6ku'].default} />
            </g>
          </g>
        </g>
        {/* 城東 エリア */}
        <g id="jotoh">
          {/* 画像 */}
          <g id="jotoh_clip">
            <g className="jotoh-i">
              <image id="jotoh_img" transform="translate(1327.02 689.12)" href={mapFilePath['jotoh'].default} />
            </g>
          </g>
        </g>
        {/* 城西 エリア */}
        <g id="josai_a">
          {/* 画像 */}
          <g id="josai_clip">
            <g className="josai-i">
              <image id="josai_img" transform="translate(963.91 796.42)" href={mapFilePath['josai'].default} />
            </g>
          </g>
        </g>
        {/* 城南 エリア */}
        <g id="jonan">
          {/* 画像 */}
          <g id="jonan_clip">
            <g className="jonan-i">
              <image id="jonan_img" transform="translate(1024.84 1005.12)" href={mapFilePath['jonan'].default} />
            </g>
          </g>
        </g>
        {/* 城北 エリア */}
        <g id="johoku">
          {/* 画像 */}
          <g id="johoku_clip">
            <g className="johoku-i">
              <image id="johoku_img" transform="translate(1085.26 689.46)" href={mapFilePath['johoku'].default} />
            </g>
          </g>
        </g>
        {/* 23区外 エリア */}
        <g id="_23kugai" data-name=" 23kugai">
          {/* 画像 */}
          <g id="_23kugai_clip" data-name=" 23kugai_clip">
            <g className="kugai23-i">
              <image
                id="_23kugai_img"
                data-name=" 23kugai_img"
                transform="translate(-11.9 694.78)"
                href={mapFilePath['kugai23'].default}
              />
            </g>
          </g>
        </g>
        {/* 神奈川 エリア */}
        <g id="kanagawa">
          {/* 画像 */}
          <g id="kanagawa_clip">
            <g className="kanagawa-i">
              <image id="kanagawa_img" transform="translate(-13.14 1109.04)" href={mapFilePath['kanagawa'].default} />
            </g>
          </g>
        </g>
        {/* 埼玉 エリア */}
        <g id="saitama">
          {/* 画像 */}
          <g id="saitama_clip">
            <g className="saitama-i">
              <image id="saitama_img" transform="translate(-13.24 -7.74)" href={mapFilePath['saitama'].default} />
            </g>
          </g>
        </g>
        {/* 千葉 エリア */}
        <g id="chiba">
          {/* 画像 */}
          <g id="chiba_clip">
            <g className="chiba-i">
              <image id="chiba_img" transform="translate(1442.83 -10.73)" href={mapFilePath['chiba'].default} />
            </g>
          </g>
        </g>
      </svg>
    </MapWrapper>
  );
};

export default BackgroundMap;
